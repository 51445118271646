import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

import { Section, Container, Row, Column, Heading } from '../components/ui';
import { Button, WaveDivider, Layout, SEO, FullwidthCTA } from '../components';
import {
    DisputeSection,
    ServicesSection,
} from '../components/section_components';

const Page = (props: RouteComponentProps) => {
    const data = useStaticQuery(graphql`
        query Images_v1_2_refactor {
            rocketScores: file(relativePath: { eq: "rocket-scores.svg" }) {
                publicURL
            }
        }
    `);

    return (
        <Layout>
            <SEO
                title="Launch Your Credit Scores | ScoreShuttle"
                descripiton="ScoreShuttle's DIY software has all of the tools you need to launch your credit scores."
            />
            <Section>
                <Container className="pb-6">
                    <Row className="flex flex-wrap lg:flex-no-wrap">
                        <Column className="text-center lg:text-left w-full lg:w-auto pr-2">
                            <div className="container-narrow">
                                <Heading
                                    headingLevel={1}
                                    text="Do more than check your credit score. LAUNCH it!"
                                    className="lg:text-left lg:mx-0"
                                />
                                <p className="font-heading text-lg sm:text-xl md:text-2xl mb-10 lg:text-left">
                                    ScoreShuttle has all the tools you need to
                                    launch your credit scores with all 3
                                    bureaus.
                                </p>
                                <ul className="font-light text-left leading-relaxed list-disc pl-5 mb-10 sm:text-md lg:text-lg">
                                    {[
                                        "Digitally Dispute Errors in ScoreShuttle's Automated Dispute Center",
                                        '3-Bureau Reports, Scores, and Credit Monitoring',
                                        'Unlock Expert-Tailored Score Secrets in Credit Camp',
                                        'Supercharged Security and ID Theft Protection',
                                    ].map((listItem, index) => (
                                        <li key={index}>{listItem}</li>
                                    ))}
                                </ul>
                                <Button
                                    theme="blue"
                                    onClick={() =>
                                        window.open(
                                            'https://app.scoreshuttle.com/setup'
                                        )
                                    }
                                >
                                    START FOR FREE
                                </Button>
                            </div>
                        </Column>
                        <Column className="lg:ml-2 lg:w-7/12 xl:w-1/2 flex">
                            <img
                                className="mx-auto w-full max-w-sm lg:max-w-full mt-8 lg:mt-0 xl:-mt-16"
                                src={data.rocketScores.publicURL}
                                alt="rocket ship"
                            />
                        </Column>
                    </Row>
                </Container>
            </Section>
            <WaveDivider
                foregroundColor="bg-white"
                backgroundColor="text-ss-blue-100"
                flip="x"
            />
            <DisputeSection
                className="bg-ss-blue-100"
                bullets={[
                    `ScoreShuttle gives you the power to dispute report errors in one click.`,
                    `AI smarts help you identify potential problem areas.`,
                    `Spot an error? Click to launch ScoreShuttle's Automated Dispute Cycle.`,
                    `Anytime an error is removed, you could see an uptick in your scores.`,
                ]}
            />
            <WaveDivider
                foregroundColor="bg-ss-blue-100"
                backgroundColor="text-white"
                flip="x"
            />
            <ServicesSection />
            <FullwidthCTA headline="Ready to launch your credit scores?" />
        </Layout>
    );
};

export default Page;
